(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .controller('AppraisalOrderAssignController', AppraisalOrderAssignController);

    AppraisalOrderAssignController.$inject = ['$mdDialog', 'order', 'assignType', 'AppraisalOrder', 'AppraiserUser', 'suggestedAppraisers', 'eligibleAppraisers', 'independentAppraisers', 'Principal', 'AlertService'];

    function AppraisalOrderAssignController ($mdDialog, order, assignType, AppraisalOrder, AppraiserUser, suggestedAppraisers, eligibleAppraisers, independentAppraisers, Principal, AlertService) {
        var vm = this;
        vm.suggestedAppraisers = suggestedAppraisers;
        vm.eligibleAppraisers = eligibleAppraisers;
        vm.independentAppraisers = independentAppraisers;
        vm.currentAppraiser = order.assignedAppraiserId ? AppraiserUser.get({id: order.assignedAppraiserId}) : null;
        vm.matchAppraisers = matchAppraisers;
        vm.getSuggestedAppraiserText = getSuggestedAppraiserText;
        vm.getAppraiserText = getAppraiserText;
        vm.assignType = assignType;
        vm.save = save;
        vm.cancel = cancel;
        vm.isSaving = false;

        vm.isAppraisalFirm = Principal.hasAnyAuthority([/^ROLE_(APPRAISAL).*/i]);
        vm.isVCEmployee = Principal.hasAnyAuthority([/^ROLE_(ADMIN|CUSTOMER_CARE_REPRESENTATIVE|VC_.*)$/i]);
        vm.filterNotNull = filterNotNull;

        // Remove the current appraiser (if any) from the list of appraisers
        if(order.assignedAppraiserId) {
            vm.suggestedAppraisers.$promise.then(function(appraisers) {
                var currentAppraiserId = order.assignedAppraiserId;
                vm.suggestedAppraisers = appraisers.filter(function(appraiser) {
                    return appraiser.id !== currentAppraiserId;
                });
            });

            vm.eligibleAppraisers.$promise.then(function(appraisers) {
                var currentAppraiserId = order.assignedAppraiserId;
                vm.eligibleAppraisers = appraisers.filter(function(appraiser) {
                    return appraiser.id !== currentAppraiserId;
                });
            });
        }

        function getSuggestedAppraiserText(item) {
            var textStrArray = [];
            // User Full Name
            textStrArray.push(item.userFullName);

            if (!vm.isAppraisalFirm) {
                // (Firm Assistant Name)
                if (item.appraisalFirmAssistantName) {
                    textStrArray.push('(' + item.appraisalFirmAssistantName + ')');
                }
                // Firm Name
                textStrArray.push('|');
                textStrArray.push(item.appraisalFirmName);
            }

            if (item.isFirmRostered) {
                textStrArray.push('| Rostered');
            }

            if (item.isFirmIndependent) {
                textStrArray.push('| Independent');
            }

            return textStrArray.join(' ');
        }

        function getAppraiserText(item) {
            var text = item.userFullName + " | " + item.appraisalFirmName;
            item.regions.forEach(function(region) {
                text += ", " + region.name;
            });

            if (item.isFirmRostered) {
                text += (' | Rostered ');
            }

            if (item.isFirmIndependent) {
                text += (' | Independent ');
            }

            return text;
        }

        function matchAppraisers(searchText, appraisers) {
            var searchRegex = new RegExp(searchText, 'gi');
            return appraisers.filter(function(appraiser) {
                var name = getAppraiserText(appraiser);
                return searchRegex.test(name);
            });
        }

        function filterNotNull(values) {
            return values.filter(function (value) {
                return value != null
            });
        }

        function save() {
            let selectedAppraiser = null;

            let possibleSelections = [vm.selectedAppraiser, vm.selectedAppraiserOther, vm.selectedAppraiserIndependent]
            if (filterNotNull(possibleSelections).length === 0) {
                AlertService.error("One appraiser must be selected");
                return;
            } else if (filterNotNull(possibleSelections).length !== 1) {
                AlertService.error("Only one appraiser can be assigned at a time");
                return;
            } else {
                selectedAppraiser = filterNotNull(possibleSelections)[0]
            }

            vm.isSaving = true;
            if (vm.assignType === "PREASSIGN_APPRAISER") {
                AppraisalOrder.preassignAppraiser({id: order.id, appraiserId: selectedAppraiser.id}, null, onSuccess, onError);
            } else if (vm.assignType === "ASSIGN_APPRAISER"
                        || !order.assignedAppraiserId) {
                AppraisalOrder.assignAppraiser({id: order.id, appraiserId: selectedAppraiser.id}, null, onSuccess, onError);
            } else {
                if(selectedAppraiser.appraisalFirmLocationId !== order.assignedAppraiser.appraisalFirmLocationId) {
                    var confirm = $mdDialog.confirm({skipHide: true})
                        .title('Selected Appraiser From Different Firm')
                        .textContent('Are you sure you want to send the order to a different firm? Any data entered in the report builder will be permanently lost.')
                        .ariaLabel('Selected Appraiser From Different Firm')
                        .ok('Yes')
                        .cancel('No');

                    $mdDialog.show(confirm)
                        .then(function () {
                            AppraisalOrder.reassignAppraiser({
                                id: order.id,
                                appraiserId: selectedAppraiser.id
                            }, null, onSuccess, onError);
                        })
                        .catch(function () {
                            vm.isSaving = false;
                        });
                }else{
                    AppraisalOrder.reassignAppraiser({
                        id: order.id,
                        appraiserId: selectedAppraiser.id
                    }, null, onSuccess, onError);
                }

            }

            function onSuccess(response) {
                vm.isSaving = false;
                $mdDialog.hide(response);
            }

            function onError(err) {
                vm.isSaving = false;
                $mdDialog.cancel({ error: "An error occurred while assigning the appraiser."});
            }
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }
})();
