(function() {
    'use strict';
    angular
        .module('valueconnectApp')
        .factory('AppraisalOrderModal', AppraisalOrderModal);

    AppraisalOrderModal.$inject = ['$q','$uibModal', '$mdDialog', '$state', '$translate', 'AppraisalOrder', 'AppraisalReport', 'DynamicForm', 'AlertService'];

    function AppraisalOrderModal ($q,$uibModal, $mdDialog, $state, $translate, AppraisalOrder, AppraisalReport, DynamicForm, AlertService) {
        var service = {
            openAppraisalOrderContactDialog: openAppraisalOrderContactDialog,
            openLenderContactDialog: openLenderContactDialog,
            requestQuote: requestQuote,
            openAcceptDialog: openAcceptDialog,
            openDeclinedDialog: openDeclinedDialog,
            openPaymentDialog: openPaymentDialog,
            openAppraisalOrderChangeRequestDialog: openAppraisalOrderChangeRequestDialog,
            scheduleVisit: scheduleVisit,
            completeVisit: completeVisit,
            restoreCancelledOrder: restoreCancelledOrder,
            cloneReport: cloneReport,
            thirdPartyUpload: thirdPartyUpload,
            assignCosigner: assignCosigner,
            assignAppraiser: assignAppraiser,
            redirectOrder: redirectOrder,
            openCcrAppraisalOrderChangeRequestDialog: openCcrAppraisalOrderChangeRequestDialog,
            openCancelAppraisalOrderDialog: openCancelAppraisalOrderDialog,
            changeDueDate: changeDueDate,
            sendReportBack: sendReportBack,
            addVCNote: addVCNote,
            changeOrderState: changeOrderState,
        };

        var requestQuoteTranslations = $translate([
            'valueconnectApp.appraisalOrder.requestQuote.title',
            'valueconnectApp.appraisalOrder.requestQuote.body',
            'valueconnectApp.appraisalOrder.requestQuote.continue',
            'entity.action.cancel'
        ]);

        var modalInstance = null;
        return service;

        function resetModal() {
            modalInstance = null;
        }

        function createDialogInstance(templateUrl, controller, resolveObj, localsObj) {
            if (modalInstance !== null) return $q.when(null);
            modalInstance = $mdDialog.show({
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: 'vm',
                animation: true,
                backdrop: 'static',
                size: 'lg',
                clickOutsideToClose: true,
                resolve: resolveObj,
                locals: localsObj
            }).then(function () {
                $state.reload();
            }).finally(resetModal);
            return modalInstance;
        }

        // Create Property Access Contact
        function openAppraisalOrderContactDialog (appraisalOrder) {
            return createDialogInstance(
                'app/entities/appraisal-order-contact/appraisal-order-contact-dialog.html',
                'AppraisalOrderContactDialogController',
                {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('appraisalOrderContact');
                        $translatePartialLoader.addPart('appraisalContactRole');
                        $translatePartialLoader.addPart('contact');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            appraisalReportSendToEmailAddress: null,
                            disabled: false,
                            appraisalOrder: null,
                            client: appraisalOrder.clientContact,
                            contact: {
                                officeAddress: {}
                            }
                        };
                    }
                }
            );
        }

        // Create Lender Contact
        function openLenderContactDialog (lenderId, showLender) {
            return createDialogInstance(
                'app/entities/lender-contact/lender-contact-dialog.html',
                'LenderContactDialogController',
                {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('appraisalOrderContact');
                        $translatePartialLoader.addPart('appraisalContactRole');
                        $translatePartialLoader.addPart('contact');
                        $translatePartialLoader.addPart('file');
                        $translatePartialLoader.addPart('lenderContact');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: null,
                            appraisalReportSendToEmailAddress: null,
                            disabled: false,
                            lenderId: lenderId,
                            contact: {}
                        };
                    }
                },
                {
                    showLender: showLender || false,
                    operation: "create"
                }
            );
        }

        // Request Manual Quote
        function requestQuote(appraisalOrder, ev) {
            return requestQuoteTranslations.then(function(translations) {
                var title = translations['valueconnectApp.appraisalOrder.requestQuote.title'];
                var body = translations['valueconnectApp.appraisalOrder.requestQuote.body'];
                var cancel = translations['entity.action.cancel'];
                var ok = translations['valueconnectApp.appraisalOrder.requestQuote.continue'];

                var dialog = $mdDialog.confirm()
                    .title(title).ariaLabel(title)
                    .htmlContent(body)
                    .targetEvent(ev)
                    .ok(ok)
                    .cancel(cancel);

                return $mdDialog.show(dialog).then(function(result) {
                    return AppraisalOrder.requestQuote({id: appraisalOrder.id}, null).$promise;
                });
            });
        }

        // Accept Assignment
        function openAcceptDialog(appraisalOrder) {
            return createDialogInstance(
                'app/entities/appraisal-order/appraisal-order-accept-assignment-dialog.html',
                'AppraisalOrderAcceptAssignmentDialogController',
                {},
                { order: appraisalOrder }
            );
        }

        // Decline Assignment
        function openDeclinedDialog (appraisalOrder) {
            return createDialogInstance(
                'app/entities/declined/declined-dialog.html',
                'DeclinedDialogController',
                {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('address');
                        $translatePartialLoader.addPart('appraisalOrderContact');
                        $translatePartialLoader.addPart('appraisalContactRole');
                        $translatePartialLoader.addPart('contact');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            appraisalOrder: appraisalOrder
                        };
                    }
                }
            );
        }

        // Make Payment
        function openPaymentDialog (split) {
            return createDialogInstance(
                'app/entities/payment/payment-dialog.html',
                'PaymentDialogController',
                {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payment');
                        $translatePartialLoader.addPart('enumPaymentMethod');
                        $translatePartialLoader.addPart('enumPaymentStatus');
                        $translatePartialLoader.addPart('paymentProfile');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            method: null,
                            amount: split.splitAmount,
                            transactionId: null,
                            transactionDate: null,
                            paymentStatus: null,
                            id: null,
                            quoteSplitId: split.id
                        };
                    }
                }
            );
        }

        // Create Change Request
        function openAppraisalOrderChangeRequestDialog (state, appraisalOrder, changeRequest) {
            return createDialogInstance(
                'app/entities/appraisal-order-change-request/appraisal-order-change-request-appraiser-dialog.html',
                'AppraisalOrderChangeRequestAppraiserDialogController',
                {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            state: state,
                            appraisalOrder: appraisalOrder,
                            appraisalOrderChangeRequest: changeRequest
                        };
                    }
                }
            );
        }

        // View Change Request
        function openCcrAppraisalOrderChangeRequestDialog (appraisalOrder, changeRequest, showDeclineAndReassign) {
            return createDialogInstance(
                'app/entities/appraisal-order-change-request/appraisal-order-change-request-dialog.html',
                'AppraisalOrderChangeRequestDialogController',
                {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            appraisalOrder: appraisalOrder,
                            appraisalOrderChangeRequest: changeRequest
                        };
                    },
                    showDeclineAndReassign: function() {
                        return showDeclineAndReassign;
                    }
                }
            );
        }

        // Cancal Appraisal Order
        function openCancelAppraisalOrderDialog(appraisalOrder) {
            return createDialogInstance(
                'app/entities/appraisal-order/cancel-appraisal-order-dialog.html',
                'CancelAppraisalOrderDialogController',
                {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            id: appraisalOrder.id,
                            reason: null
                        };
                    }
                }
            );
        }

        // Schedule Inspection
        function scheduleVisit(appraisalOrder) {
            return createDialogInstance(
                'app/entities/appraisal-order/schedule-visit-dialog.html',
                'ScheduleVisitDialogController',
                {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    order: function () {
                        return appraisalOrder;
                    }
                }
            );
        }

        /**
         * Show dialog to complete the appraisal visit
         * @param  {Object}  appraisalOrder The appraisal order to update
         * @param  {Event}   ev             The click event that triggered the modal
         * @return {Promise}                A promise that will either be cancelled, or resolved with the updated appraisal order
         */
        function completeVisit(appraisalOrder, ev) {
            var dialog = $mdDialog.show($mdDialog.confirm()
                .title('Complete Inspection')
                .textContent('Are you sure you want to mark your inspection for this appraisal as completed?')
                .ariaLabel('Complete Inspection')
                .targetEvent(ev)
                .ok('COMPLETE INSPECTION')
                .cancel('CANCEL'));

            // Send update request to server once dialog has been resolved
            return dialog.then(function () {
                return AppraisalReport.completeVisit(appraisalOrder.activeReport, {}).$promise.then(function (data) {
                    return data;
                });
            });
        }

        /**
         * Show dialog to search for a report, and then clone its forms onto the active report for
         * the specified order
         * @param  {Object}  appraisalOrder The target appraisal order
         * @param  {Event}   ev             The click event that triggered the modal
         * @return {Promise}                A promise that will be resolved with the cloned report
         */
        function cloneReport(appraisalOrder, ev) {
            return $mdDialog.show({
                templateUrl: 'app/components/search/report-search-dialog.template.html',
                controller: 'ReportSearchDialogController',
                controllerAs: 'vm',
                targetEvent: ev,
                clickOutsideToClose: true
            }).then(function(cloneReport) {
                return AppraisalReport.cloneForms({sourceId: cloneReport.id, targetId: appraisalOrder.activeReport.id}, null).$promise;
            });
        }

        /**
         * Show a confirmation dialog then call endpoint to delete existing forms on the specified
         * report and use the third party upload form
         * @param  {Object}  appraisalReport The target appraisal report
         * @param  {Event}   ev             The click event that triggered the modal
         * @return {Promise}                A promise that will be resolved with the created form
         */
        function thirdPartyUpload(appraisalReport, ev) {
            var translationKeys = ['description', 'warning', 'instructions'].map(function(suffix) {
                return 'valueconnectApp.appraisalReport.useThirdParty.' + suffix;
            });

            // Only show warning if the report has already been started
            if(appraisalReport.started === false) translationKeys.splice(1, 1);

            // Get a translation for the dialog message
            return $translate(translationKeys).then(function(translations) {
                var message = Object.keys(translations).map(function(key) {
                    return translations[key];
                }).join('');

                // Show the dialog
                return $mdDialog.show($mdDialog.confirm()
                    .title('Third Party Report Sender')
                    .htmlContent(message)
                    .ok('OK')
                    .cancel('CANCEL')
                    .targetEvent(ev)
                    .clickOutsideToClose(true)
                ).then(function() {
                    // Replace existing forms with third party form
                    return DynamicForm.useThirdParty({reportId: appraisalReport.id}, null).$promise;
                });
            });
        }

        // Assign Cosigner
        function assignCosigner(appraisalOrder, cosigners) {
            return createDialogInstance(
                'app/entities/appraisal-order/appraisal-order-assign-cosigner-dialog.html',
                'AppraisalOrderAssignCosignerDialogController',
                {
                    suggestedAppraisers: ['AppraiserUser', function(AppraiserUser) {
                        return cosigners || AppraiserUser.getCosigners({orderId: appraisalOrder.id}).$promise;
                    }]
                },
                { order: appraisalOrder }
            );
        }

        // Assign Appraiser
        // @param assignType - ASSIGN_APPRAISER, REASSIGN_APPRAISER, PREASSIGN_APPRAISER
        function assignAppraiser(appraisalOrder, assignType) {
            return createDialogInstance(
                'app/entities/appraisal-order/appraisal-order-assign.html',
                'AppraisalOrderAssignController',
                {
                    suggestedAppraisers: ['AppraiserUser', function(AppraiserUser) {
                        return AppraiserUser.getSuggested({id: appraisalOrder.id}).$promise;
                    }],
                    eligibleAppraisers: ['AppraiserUser', function(AppraiserUser) {
                        return AppraiserUser.getEligible({id: appraisalOrder.id}).$promise;
                    }],
                    independentAppraisers: ['AppraiserUser', function(AppraiserUser) {
                        return AppraiserUser.getIndependent({id: appraisalOrder.id}).$promise;
                    }],
                },
                { order:  appraisalOrder, assignType: assignType }
            );
        }

        // Redirect Order to Another Lender
        function redirectOrder(appraisalOrder) {
            return createDialogInstance(
                'app/entities/appraisal-order/appraisal-order-redirect-dialog.html',
                'AppraisalOrderRedirectDialogController',
                {},
                {appraisalOrder: angular.copy(appraisalOrder)}
            );
        }

        // Restore cancelled order
        function restoreCancelledOrder(appraisalOrder) {
            return createDialogInstance(
                'app/entities/appraisal-order/appraisal-order-restore-dialog.html',
                'AppraisalOrderRestoreDialogController',
                {},
                {appraisalOrder: angular.copy(appraisalOrder)}
            );
        }

        // Send Report Back to Appraiser
        function sendReportBack(appraisalReport) {
            return createDialogInstance(
                'app/entities/appraisal-report/appraisal-report-send-back-dialog.html',
                'AppraisalReportSendBackDialogController',
                {},
                {appraisalReport: angular.copy(appraisalReport)}
            );
        }

        // Change Appraisal Due Date
        function changeDueDate(appraisalOrder) {
            return createDialogInstance(
                'app/entities/appraisal-order/appraisal-order-change-duedate-dialog.html',
                'AppraisalOrderChangeDueDateDialogController',
                {},
                { appraisalOrder: angular.copy(appraisalOrder) }
            );
        }

        // Create Internal Appraisal Note
        function addVCNote(appraisalOrder) {
            return createDialogInstance(
                'app/entities/vc-note/vc-note-dialog.html',
                'VCNoteDialogController',
                {},
                {
                    entity: {
                        note: null,
                        appraisalOrderId: appraisalOrder.id,
                        files: []
                    }
                }
            );
        }

        function changeOrderState(appraisalOrder) {
            return createDialogInstance(
                'app/entities/appraisal-order/appraisal-order-change-state.html',
                'AppraisalOrderChangeStateController',
                {},
                {
                    appraisalOrder: appraisalOrder,
                }
            )
        }
    }
})();
