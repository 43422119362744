(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('valueconnectApp')
        .constant('VERSION', "0.0.1-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('SENTRY_ENABLED', true)
        .constant('GOOGLE_MAP_API_KEY', "AIzaSyDLYIWSSnbIfzLGT259PFbccfZMdImyOuc")
        .constant('GA4_MEASUREMENT_ID', "G-42QCVH6MEH")
        .constant('FIREBASE_CONFIG', {
	"apiKey": "AIzaSyDMFMy96OvoOuJ5bEXsVXFcwmjUkSZVzlQ",
	"authDomain": "value-connect-uat.firebaseapp.com",
	"projectId": "value-connect-uat",
	"storageBucket": "value-connect-uat.appspot.com",
	"messagingSenderId": "827896191858",
	"appId": "1:827896191858:web:20855b4c561e8eeca35beb"
})
;
})();
